define("discourse/plugins/discourse-uipath/discourse/templates/connectors/create-account-before-password/accept-terms", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.authOptions.requires_terms_acceptance}}
    <label class="uipath-terms-acceptance">
      <Input
        @type="checkbox"
        @checked={{@outletArgs.authOptions.termsAccepted}}
        {{on
          "click"
          (fn
            (mut @outletArgs.authOptions.termsAccepted)
            (not @outletArgs.authOptions.termsAccepted)
          )
        }}
      />
      <span>{{html-safe (i18n "uipath.terms_checkbox_label")}}</span>
    </label>
  {{/if}}
  */
  {
    "id": "urf0X+bM",
    "block": "[[[41,[30,1,[\"authOptions\",\"requires_terms_acceptance\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"uipath-terms-acceptance\"],[12],[1,\"\\n    \"],[8,[39,1],[[4,[38,2],[\"click\",[28,[37,3],[[28,[37,4],[[30,1,[\"authOptions\",\"termsAccepted\"]]],null],[28,[37,5],[[30,1,[\"authOptions\",\"termsAccepted\"]]],null]],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"authOptions\",\"termsAccepted\"]]]],null],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,6],[[28,[37,7],[\"uipath.terms_checkbox_label\"],null]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"input\",\"on\",\"fn\",\"mut\",\"not\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-uipath/discourse/templates/connectors/create-account-before-password/accept-terms.hbs",
    "isStrictMode": false
  });
});